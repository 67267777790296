import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selected = '';

  languages = [
    { text: 'English', value: 'en', img: 'assets/img/flags/en.webp', selectable: false},
    { text: 'Español', value: 'es', img: 'assets/img/flags/es.webp', selectable: true},
    { text: 'Galego', value: 'gl', img: 'assets/img/flags/gl.webp', selectable: false},
    { text: 'Català', value: 'ca', img: 'assets/img/flags/ca.webp', selectable: false},
    { text: 'Português', value: 'pt', img: 'assets/img/flags/pt.webp', selectable: true},
    { text: 'Français', value: 'fr', img: 'assets/img/flags/fr.webp', selectable: false},
    { text: 'Euskera', value: 'eu', img: 'assets/img/flags/eu.webp', selectable: false}
  ];

  constructor(
    private translate: TranslateService
  ) { }

    setInitialLanguage() {
      // Para obtener el idioma del propio dispositivo
      const language = this.translate.getBrowserLang();
      this.translate.setDefaultLang(language);

      // this.translate.setDefaultLang('es');

      const lng = localStorage.getItem(LNG_KEY);
      if (lng) {
        this.setLanguage(lng);
        this.selected = lng;
      }
    }

    public getLanguages() {
      const selectableLanguages = [];
      this.languages.forEach(language => {
        if (language.selectable) {
          selectableLanguages.push(language);
        }
      });
      return selectableLanguages;
    }

    showLanguages() {
      return this.getLanguages().length > 1 ? true : false;
    }

    setLanguage(lng) {
      this.translate.use(lng);
      this.selected = lng;
      localStorage.setItem(LNG_KEY, lng);
    }
}
